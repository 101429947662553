body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#welcome {
  /* background: url('https://picsum.photos/id/1025/4951/3301'); */
  background: url(/assets/1025-2242x1080.png);
  background-size: cover;
  background-position: top;
  min-height: 1000px;
}

.slides img {
  background-size: cover;
  background-position: center;
}

@media screen and (max-width: 670px) {
  #welcome {
    min-height: 600px;
  }
  .parallax-container .parallax img {
    min-height: 0;
  }
}

footer .footer-copyright {
  font-size: small;
}

footer img.responsive-img {
  max-width: 40%;
}

a:hover {
  text-decoration: underline;
  text-decoration-color: aliceblue;
}
